.customerMainDiv {
    margin-left: 260px;
    padding-top: 20px;
}


/* DESKTOP */
@media (min-width:700px){
    .customerMainDiv {
        margin-left: 260px;
        padding-top: 20px;
    }
}





/* MOBILE */
@media (max-width:700px){
    .customerMainDiv {
        margin-left: 50px;
        padding-top: 20px;
    }
}