.ordersMainDiv {
    
}

.ordersTable, th, tr, td {

}

.ordersTable {
    margin: auto;
    margin-top: 30px;
    width: 70%;
}

.ordersTableHead{
    background-color: black;
    color: white;
}

.ordersTableRow {
    height: 40px;
    border-top: 5px solid white;
    background-color: #e6e6e6;
}

.ordersTableRow:hover {
    height: 40px;
    border-top: 5px solid white;
    background-color: #cccccc;
}

.ordersTableHeadData {
    padding: 10px;
}