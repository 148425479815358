/* DESKTOP */
@media (min-width:700px){
    /* Modal Content */
    .order-modal-content {
        width: 90%;
        margin: auto;
        padding: 0;
        position: relative;
        border: none;
        border-radius: 10px 10px 10px 10px;
        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.4s;
        animation-name: animatetop;
        animation-duration: 0.4s
    }

    /* Add Animation */
    @-webkit-keyframes animatetop {
        from {
            top: -300px;
            opacity: 0
        }

        to {
            top: 0;
            opacity: 1
        }
    }

    @keyframes animatetop {
        from {
            top: -300px;
            opacity: 0 
        }

        to {
            top: 0;
            opacity: 1
        }
    }

    /* The Close Button */
    .order-close {
        color: white;
        float: right;
        font-size: 40px;
        font-weight: bold;
    }

    .order-close:hover,
    .order-close:focus {
        color: #706f6f;
        text-decoration: none;
        cursor: pointer;
    }

    .order-modal-header,
    .order-modal-footer {
        padding: 0px 16px;
        height: 60px;
        vertical-align: middle;
        line-height: 50px;
        text-align: center;
        background-color: #e60000;
    }

    .order-modal-header {
        border-radius: 10px 10px 0px 0px;
    }

    .order-modal-body {
        padding: 0px 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fefefe;
        min-height: 200px;
        width: 100%;
    }

    .order-modal-footer {
        border-radius: 0px 0px 10px 10px;
    }

    .order-loadingGifDiv {
        display: flex;
        justify-content: center;
    }

    .order-loadingGif {
        width: 50%;
        margin: 55px;
        justify-content: center;
    }

    .order-modalHeaderText {
        text-align: center;
        font-size: 50px;
        padding-top: 20px;
        color: #706f6f;
    }

    .order-modalMessage {
        font-size: 30px;
        font-weight: 350;
        padding-top: 20px;
        color: #706f6f;
    }

    .order-modalMessageId {
        font-size: 50px;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #c48e38;
        font-weight: 600;
    }
     
    .orderTable {
        margin: auto;
        margin-bottom: 20px;
    }
    
    .orderTableHead{
        background-color: black;
        color: white;
    }
    
    .orderTableRow {
        height: 40px;
        border-top: 5px solid white;
        background-color: #e6e6e6;
    }
    
    .orderTableHeadData {
        padding: 10px;
    }

    .orderTableCell {
        padding: 10px 20px 10px 20px;
        text-align: left;
    }

    .order-HeaderDiv {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 60%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    }
    .Order-Number, .Order-Customer {
        font-size: 25px;
    }
    .orderTableTopRightData, .orderTableTopLeftData {
        font-size: 25px;
        padding-top: 15px;
        padding-bottom: 15px;
    } 
    .orderTablePostage, .orderTableTotal {
        text-align: right;
        padding-right: 10px;
        padding-top: 10px;
        font-size: 20px;
    }
    .customerOrderTableTopData {
        font-size: 35px;
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: center;
    } 
}


/* MOBILE */
@media (max-width:700px){
     /* Modal Content */
     .order-modal-content {
        width: 90%;
        margin: auto;
        padding: 0;
        position: relative;
        border: none;
        border-radius: 10px 10px 10px 10px;
        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.4s;
        animation-name: animatetop;
        animation-duration: 0.4s
    }

    /* Add Animation */
    @-webkit-keyframes animatetop {
        from {
            top: -300px;
            opacity: 0
        }

        to {
            top: 0;
            opacity: 1
        }
    }

    @keyframes animatetop {
        from {
            top: -300px;
            opacity: 0 
        }

        to {
            top: 0;
            opacity: 1
        }
    }

    /* The Close Button */
    .order-close {
        color: white;
        float: right;
        font-size: 40px;
        font-weight: bold;
    }

    .order-close:hover,
    .order-close:focus {
        color: #706f6f;
        text-decoration: none;
        cursor: pointer;
    }

    .order-modal-header,
    .order-modal-footer {
        padding: 0px 16px;
        height: 60px;
        vertical-align: middle;
        line-height: 50px;
        text-align: center;
        background-color: #e60000;
    }

    .order-modal-header {
        border-radius: 10px 10px 0px 0px;
    }

    .order-modal-body {
        padding: 0px 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fefefe;
        min-height: 200px;
        width: 100%;
    }

    .order-modal-footer {
        border-radius: 0px 0px 10px 10px;
    }

    .order-loadingGifDiv {
        display: flex;
        justify-content: center;
    }

    .order-loadingGif {
        width: 50%;
        margin: 55px;
        justify-content: center;
    }

    .order-modalHeaderText {
        text-align: center;
        font-size: 30px;
        padding-top: 20px;
        color: #706f6f;
    }

    .order-modalMessage {
        font-size: 20px;
        font-weight: 350;
        padding-top: 20px;
        color: #706f6f;
    }

    .order-modalMessageId {
        font-size: 30px;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #c48e38;
        font-weight: 600;
    }
     
    .orderTable {
        margin: auto;
        margin-bottom: 20px;
    }
    
    .orderTableHead{
        background-color: black;
        color: white;
    }
    
    .orderTableRow {
        height: 40px;
        border-top: 5px solid white;
        background-color: #e6e6e6;
    }
    
    .orderTableHeadData {
        padding: 5px;
    }

    .orderTableCell {
        padding: 3px 7px 3px 7px;
        text-align: left;
        font-size: 13px;
    }

    .order-HeaderDiv {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    }
    .customerOrderTableTopData {
        font-size: 25px;
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: center;
    } 
    .orderTablePostage, .orderTableTotal {
        text-align: right;
        padding-right: 10px;
        padding-top: 10px;
        font-size: 15px;
    }
}