@media (max-width:700px){ /* --- Mobile CSS--- Mobile CSS--- Mobile CSS--- Mobile CSS--- Mobile CSS--- Mobile CSS  */

    .Logo_Login {
        width: 90%;
    }

}

@media (min-width:700px){ /* --- Desktop  --- Desktop  --- Desktop  --- Desktop  --- Desktop  --- Desktop  */

    .Logo_Login {
        width: 40%;
    }
    
}