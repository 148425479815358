@media (min-width:700px){
  .App {
    text-align: center;
  }

  .MainAppLogo {
    width: 50vh;
    padding-top: 10px;
    margin-bottom: 30px;
  }

  .MainAppRowRed{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    padding: 20px;
    background-color: #cc2222;
  }
  .MainAppRowWhite{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    padding: 20px;
    background-color: #ffffff;
  }
  .MainAppRowImageDiv {
    width: 40%;
  }
  .MainAppRowImage {
    width: 40%;
  }
  .MainAppRowImage2 {
    width: 70%;
  }
  .MainAppRowTextDiv {
    width: 40%;
  }
  .MainAppRowTextBlack {
    font-family: scriptorama-markdown-jf, sans-serif;
    font-size: 20px;
    color: #000000;
  }
  .MainAppRowTextWhite {
    font-family: scriptorama-markdown-jf, sans-serif;
    font-size: 20px;
    margin: 0px;
    color: #ffffff;
  }
  .svgTest {
    width: 20%;
  }
}



@media (max-width:700px){
  .App {
    text-align: center;
  }

  .MainAppLogo {
    width: 50vh;
    padding-top: 10px;
  }

  .MainAppRow{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    background-color: #cc2222;
  }
}