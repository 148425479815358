/* DESKTOP */
@media (min-width:700px){
    .BasketTable {
        margin: auto;
        margin-bottom: 20px;
        user-select: none;
    }
    .basketTableHead{
        background-color: #3366ff;
        color: white;
    }
    .basketTableRow {
        height: 40px;
        border-top: 5px solid white;
        background-color: #e6e6e6;
    }
    .basketTableHeadData {
        padding: 10px;
    }
    .basketTableTopRightData, .basketTableTopLeftData {
        font-size: 25px;
        padding-top: 15px;
        padding-bottom: 15px;
    } 
    .basketTablePostage, .basketTableTotal {
        text-align: right;
        padding-right: 10px;
        padding-top: 10px;
        font-size: 20px;
    }
    .basketTableCell {
        padding: 10px 20px 10px 20px;
        text-align: left;
    }
    .basketTableCellQty {
        text-align: center;
        vertical-align: middle;
        vertical-align: middle;
        padding: 0px 5px;
    }
    .basketUpQtyButton {
        background-color: #4CAF50; /* Green */
        border: none;
        color: white;
        padding: 3px 12px 4px 12px;
        text-align: center;
        vertical-align: middle;
        text-decoration: none;
        font-size: 16px;
        font-weight: bolder;
        margin: auto;
    }
    .basketDownQtyButton {
        background-color: #4CAF50; /* Green */
        border: none;
        color: white;
        padding: 3px 13px 4px 13px;
        text-align: center;
        vertical-align: middle;
        text-decoration: none;
        font-size: 16px;
        font-weight: bolder;
        margin: auto;
    }
}


/* MOBILE */
@media (max-width:700px){
    .basketBasketTable {
        margin: auto;
        margin-bottom: 20px;
        user-select: none;
    }
    .basketTableHead{
        background-color: black;
        color: white;
    }
    .basketTableRow {
        height: 40px;
        border-top: 5px solid white;
        background-color: #e6e6e6;
    }
    .basketTableHeadData {
        padding: 10px;
    }
    .order-HeaderDiv {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    } 
    .basketTablePostage, .basketTableTotal {
        text-align: right;
        padding-right: 10px;
        padding-top: 10px;
        font-size: 15px;
    }
    .basketTableCell {
        padding: 3px 7px 3px 7px;
        text-align: left;
        font-size: 13px;
    }
}