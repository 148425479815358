/* DESKTOP */
@media (min-width:700px){
    .customerOrdersMainDiv {
        
    }
    .customerOrdersTable, th, tr, td {

    }
    .customerOrdersTable {
        margin: auto;
        margin-top: 30px;
        width: 70%;
    }
    .customerOrdersTableHead{
        background-color: black;
        color: white;
        font-size: 20px;
    }
    .customerOrdersTableRow {
        height: 40px;
        border-top: 5px solid white;
        background-color: #e6e6e6;
    }
    .customerOrdersTableRow:hover {
        height: 40px;
        border-top: 5px solid white;
        background-color: #cccccc;
    }
    .customerOrdersTableHeadData {
        padding: 10px;
    }
    .customerOrdersTableData {
        font-size: 20px;
        padding: 10px;
    }
}


/* MOBILE */
@media (max-width:700px){
    .customerOrdersMainDiv {
        
    }
    .customerOrdersTable, th, tr, td {

    }
    .customerOrdersTable {
        margin: auto;
        margin-top: 30px;
        width: 95%;
    }
    .customerOrdersTableHead{
        background-color: black;
        color: white;
        font-size: 12px;
        user-select: none;
    }
    .customerOrdersTableRow {
        height: 40px;
        border-top: 5px solid white;
        background-color: #e6e6e6;
    }
    .customerOrdersTableHeadData {
        padding: 10px;
    }
    .customerOrdersTableData {
        font-size: 12px;
        padding: 5px;
        user-select: none;
    }
}