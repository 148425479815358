@media (min-width:700px){
    .customerAccountMainDiv {
        margin-left: 270px;
    }
    .customerAccountRow {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start
    }
}

@media (max-width:701px){

}