@media (min-width:700px){
    .customTextMainDiv {
        
    }
    .customTextRowDiv {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin: 10px 30px 10px 30px;
        border: 4px solid rgb(202, 200, 200);
        border-radius: 15px;
        padding: 20px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    .customTextRowDivHidden {
        display: none;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    .customTextRowButtonsDiv {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: flex-start;
        align-content: center;
        width: 100%;
    }
    .customTextItemDiv {
        padding: 10px 20px 10px 20px;
        background-color: #b3b3b3;
        color: #ffffff;
        width: 20%;
    }
    .customTextItemDivSelected {
        padding: 10px 20px 10px 20px;
        background-color: #009933;
        color: #ffffff;
        width: 20%;
    }
    .customTextThicknessDiv {
        padding: 10px 20px 10px 20px;
        background-color: #b3b3b3;
        color: #ffffff;
        width: 20%;
    }
    .customTextThicknessDivSelected {
        padding: 10px 20px 10px 20px;
        background-color: #009933;
        color: #ffffff;
        width: 20%;
    }
    .customTextItemDivText {
        font-size: 20px;
    }
    .customTextInputDiv {
        width: 100%;
        margin-top: 20px;
    }
    .customTextInput {
        width: 70%;
    }
    .customTextSizeInput {
        width: 20%;
        font-size: 30px;
    }
    .textSizingImage {
        width: 45%;
    }
    .customTextHeading {
        font-size: 40px;
    }
    .priceHeading {
        font-size: 50px;
    }
    .customTextInputHeading {
        font-size: 25px;
    }
    .customTextHeadingNotes {
        font-size: 20px;
    }
    .customTextNotesTextArea {
        width: 50%;
        height: 60px;
    }
}






@media (max-width:700px){
    .customTextMainDiv {

    }
    .customTextRowDiv {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin: 10px 30px 10px 30px;
        border: 4px solid rgb(202, 200, 200);
        border-radius: 15px;
        padding: 20px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    .customTextRowDivHidden {
        display: none;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    .customTextRowButtonsDiv {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: center;
        align-content: center;
        width: 100%;
    }
    .customTextItemDiv {
        background-color: #b3b3b3;
    }
    .customTextItemDivSelected {
        background-color: #009933;
    }
    .customTextItemDiv, .customTextItemDivSelected {
        color: #ffffff;
        width: 60%;
        padding: 10px 20px 10px 20px;
        margin-bottom: 10px;
    }
    .customTextThicknessDiv {
        background-color: #b3b3b3;
    }
    .customTextThicknessDivSelected {
        background-color: #009933;
    }
    .customTextThicknessDivSelected, .customTextThicknessDiv {
        color: #ffffff;
        padding: 10px 20px 10px 20px;
        width: 60%;
        margin-bottom: 10px;
    }
    .customTextItemDivText {
        font-size: 15px;
    }
    .customTextInputDiv {
        width: 100%;
        margin-top: 20px;
    }
    .customTextInput {
        width: 70%;
    }
    .customTextSizeInput {
        width: 40%;
        font-size: 20px;
    }
    .textSizingImage {
        width: 55%;
    }
    .customTextHeading {
        font-size: 25px;
    }
    .priceHeading {
        font-size: 50px;
    }
    .customTextInputHeading {
        font-size: 15px;
    }
    .customTextHeadingNotes {
        font-size: 15px;
    }
    .customTextNotesTextArea {
        width: 95%;
        height: 60px;
    }
}
