/* DESKTOP */
@media (min-width:700px){
    .productCostingMainSplitDiv {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }

    .productCostingLeftSplitDiv {
        width: 65%;
    }

    .productCostingRightSplitDiv {
        width: 35%;
    }

    .productCostingRowHideDiv {
        display: none;
    }

    .productCostingRowShowDiv {
        display: block;
    }
}





/* MOBILE */
@media (max-width:700px){

}