.customersMainDiv {
    
}

.customersTable, th, tr, td {

}

.customersTable {
    margin: auto;
    margin-top: 30px;
    width: 70%;
}

.customersTableHead{
    background-color: black;
    color: white;
}

.customersTableRow {
    height: 40px;
    border-top: 5px solid white;
    background-color: #e6e6e6;
}

.customersTableRow:hover {
    height: 40px;
    border-top: 5px solid white;
    background-color: #cccccc;
}

.customersTableHeadData {
    padding: 10px;
}