/* DESKTOP */
@media (min-width:700px){
    /* Modal Content */
    .modal-content {
        width: 60%;
        margin: auto;
        padding: 0;
        position: relative;
        border: none;
        border-radius: 10px 10px 10px 10px;
        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.4s;
        animation-name: animatetop;
        animation-duration: 0.4s
    }

    /* Add Animation */
    @-webkit-keyframes animatetop {
        from {
            top: -300px;
            opacity: 0
        }

        to {
            top: 0;
            opacity: 1
        }
    }

    @keyframes animatetop {
        from {
            top: -300px;
            opacity: 0
        }

        to {
            top: 0;
            opacity: 1
        }
    }

    /* The Close Button */
    .close {
        color: white;
        float: right;
        font-size: 40px;
        font-weight: bold;
    }

    .close:hover,
    .close:focus {
        color: #706f6f;
        text-decoration: none;
        cursor: pointer;
    }

    .modal-header,
    .modal-footer {
        padding: 2px 16px;
        height: 50px;
        vertical-align: middle;
        line-height: 50px;
        text-align: center;
        background-color: #f9b233;
    }

    .modal-header {
        border-radius: 10px 10px 0px 0px;
    }

    .modal-body {
        padding: 2px 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fefefe;
        min-height: 200px;
    }

    .modal-footer {
        border-radius: 0px 0px 10px 10px;
    }

    .loadingGifDiv {
        display: flex;
        justify-content: center;
    }

    .loadingGif {
        width: 50%;
        margin: 55px;
        justify-content: center;
    }

    .modalHeaderText {
        text-align: center;
        font-size: 50px;
        padding-top: 20px;
        color: #706f6f;
    }

    .modalMessage {
        font-size: 30px;
        font-weight: 350;
        padding-top: 20px;
        color: #706f6f;
    }

    .modalMessageId {
        font-size: 50px;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #c48e38;
        font-weight: 600;
    }
}





/* MOBILE */
@media (max-width:700px){
      /* Modal Content */
    .modal-content {
        width: 98%;
        margin: auto;
        padding: 0;
        position: relative;
        border: none;
        border-radius: 10px 10px 10px 10px;
        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.4s;
        animation-name: animatetop;
        animation-duration: 0.4s
    }

    /* Add Animation */
    @-webkit-keyframes animatetop {
        from {
            top: -300px;
            opacity: 0
        }

        to {
            top: 0;
            opacity: 1
        }
    }

    @keyframes animatetop {
        from {
            top: -300px;
            opacity: 0
        }

        to {
            top: 0;
            opacity: 1
        }
    }

    /* The Close Button */
    .close {
        color: white;
        float: right;
        font-size: 40px;
        font-weight: bold;
    }

    .close:hover,
    .close:focus {
        color: #706f6f;
        text-decoration: none;
        cursor: pointer;
    }

    .modal-header,
    .modal-footer {
        padding: 2px 16px;
        height: 50px;
        vertical-align: middle;
        line-height: 50px;
        text-align: center;
        background-color: #f9b233;
    }

    .modal-header {
        border-radius: 10px 10px 0px 0px;
    }

    .modal-body {
        padding: 2px 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        background-color: #fefefe;
        min-height: 200px;
    }

    .modal-footer {
        border-radius: 0px 0px 10px 10px;
    }

    .loadingGifDiv {
        display: flex;
        justify-content: center;
    }

    .loadingGif {
        width: 90%;
        margin: 55px;
        justify-content: center;
    }

    .modalHeaderText {
        text-align: center;
        font-size: 30px;
        padding-top: 20px;
        color: #706f6f;
    }

    .modalMessage {
        font-size: 20px;
        padding-top: 20px;
        color: #706f6f;
        font-weight: 450;
    }

    .modalMessageId {
        font-size: 40px;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #c48e38;
        font-weight: 600;
    }
}