@media (min-width:700px){
/* ENTIRE SIDEBAR */
.sidebar-full{
    display: flex;
    flex-direction: column;
    width: 250px;
    position: fixed;
    height: 100vh;
    background-color: #e60000;
    margin: 0px;
    transition: width 1s;
    box-shadow: 0px 0px 10px 0px black;
    -ms-user-select:none;
    -moz-user-select:none;
    -webkit-user-select:none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    user-select:none;
}

.sidebar-minimised {
    width: 66px;
    position: fixed;
    height: 100vh;
    background-color:#e60000;
    margin: 0px;
    transition: width 1s;
    box-shadow: 0px 0px 10px 0px black;
}


/* SIDEBAR LOGO */
.sidebar-logo-div {
    background-color:#ffffff;
    margin-bottom: 10px;
}
.sidebar-logo-full {
    width: 200px;
    padding: 8px 0px 8px 0px;
    transition: width 1s;
}
.sidebar-logo-min {
    height: 75px;
    padding: 8px 0px 8px 0px;
    transition: width 1s;
}


/* SIDEBAR ITEMS */
.sidebar-item-main-item {
    display: flex;
    width: 100%;
    min-height: 50px;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    font-size: 0;
    transition: none;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: color 0 ease-in;
}
.sidebar-item {
    display: flex;
    width: 100%;
    min-height: 50px;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 0;
    transition: none;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: color 0 ease-in;
}
.sidebar-item-padding-div {
    padding-top: 5px;
    padding-bottom: 5px;
}
.sidebar-item-main-item:hover, .sidebar-item-dropdown-item:hover, .sidebar-minimise-div:hover, .sidebar-signout-div:hover {
    background-color: #e60000;
}
.sidebar-item-text {
    white-space: nowrap;
    position: static;
    opacity: 1;
    transition: opacity 1s linear;
    padding-left: 10px;
    padding-bottom: 5px;
    margin: 0px;
    color: #ffffff;
    font-size: 22px;
    font-weight: 500;
}
.sidebar-item-text-min {
    position: static;
    opacity: 0;
    transition: opacity 1s linear;
}

/* SIDEBAR ITEM DROPDOWNS */
.sidebar-item-dropdown-outer {
    width: 100%;
    min-height: 50px;
    font-size: 0;
    background-color: #e60000;
}
.sidebar-item-dropdown-outer-hide {
    display: none;
}
.sidebar-item-dropdown-item {
    display: flex;
    justify-content: flex-start;
    padding-left: 55px;
    width: 100%;
    height: 50px;
}
.sidebar-dropdown-item-text {
    white-space: nowrap;
    display: inline-block;
    line-height: 45px;
    opacity: 1;
    padding-left: 10px;
    margin: 0px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
}

/* SIDEBAR BOTTOM ITEMS */
.sidebar-bottom-item-text {
    white-space: nowrap;
    position: static;
    opacity: 1;
    transition: opacity 1s linear;
    padding-left: 10px;
    padding-bottom: 5px;
    margin: 0px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
}

.sidebar-minimise-div {
    position: absolute;
    height: 50px;
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding-left: 15px;
    font-size: 0;
    transition: none;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: color 0 ease-in;
    margin-top: 10px;
    bottom: 70px;
    left: 0;
}

.sidebar-signout-div {
    position: absolute;
    height: 50px;
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    font-size: 0;
    transition: none;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: color 0 ease-in;
    margin-top: 10px;
    margin-bottom: 20px;
    bottom: 0;
    left: 0;
}
}
















@media (max-width:700px){
/* ENTIRE SIDEBAR */
.sidebar-full{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    height: 100vh;
    background-color: #e60000;
    margin: 0px;
    transition: width 1s;
    box-shadow: 0px 0px 10px 0px black;
    -ms-user-select:none;
    -moz-user-select:none;
    -webkit-user-select:none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    user-select:none;
}

.sidebar-minimised {
    width: 50px;
    position: fixed;
    height: 100vh;
    background-color:#e60000;
    margin: 0px;
    transition: width 1s;
    box-shadow: 0px 0px 10px 0px black;
}


/* SIDEBAR LOGO */
.sidebar-logo-div {
    background-color:#ffffff;
    margin-bottom: 10px;
}
.sidebar-logo-full {
    width: 200px;
    padding: 8px 0px 8px 0px;
    transition: width 1s;
}
.sidebar-logo-min {
    width: 40px;
    padding: 8px 0px 8px 0px;
    transition: width 1s;
}


/* SIDEBAR ITEMS */
.sidebar-item-main-item {
    display: flex;
    width: 100%;
    min-height: 40px;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 7px;
    padding-right: 5px;
    font-size: 0;
    transition: none;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: color 0 ease-in;
}
.sidebar-item {
    display: flex;
    width: 100%;
    min-height: 40px;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 0;
    transition: none;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: color 0 ease-in;
}
.sidebar-item-padding-div {
    padding-top: 5px;
    padding-bottom: 5px;
}
.sidebar-item-text {
    white-space: nowrap;
    position: static;
    opacity: 1;
    transition: opacity 1s linear;
    padding-left: 10px;
    padding-bottom: 5px;
    margin: 0px;
    color: #ffffff;
    font-size: 22px;
    font-weight: 500;
}
.sidebar-item-text-min {
    position: static;
    opacity: 0;
    transition: opacity 1s linear;
}

/* SIDEBAR ITEM DROPDOWNS */
.sidebar-item-dropdown-outer {
    width: 100%;
    min-height: 50px;
    font-size: 0;
    background-color: #e60000;
}
.sidebar-item-dropdown-outer-hide {
    display: none;
}
.sidebar-item-dropdown-item {
    display: flex;
    justify-content: flex-start;
    padding-left: 55px;
    width: 100%;
    height: 50px;
}
.sidebar-dropdown-item-text {
    white-space: nowrap;
    position: static;
    opacity: 1;
    padding-left: 10px;
    padding-top: 5px;
    margin: 0px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
}

/* SIDEBAR BOTTOM ITEMS */
.sidebar-bottom-item-text {
    white-space: nowrap;
    position: static;
    opacity: 1;
    transition: opacity 1s linear;
    padding-left: 10px;
    padding-bottom: 5px;
    margin: 0px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
}

.sidebar-minimise-div {
    position: absolute;
    height: 50px;
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding-left: 8px;
    font-size: 0;
    transition: none;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: color 0 ease-in;
    margin-top: 10px;
    bottom: 70px;
    left: 0;
}

.sidebar-signout-div {
    position: absolute;
    height: 50px;
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding-left: 7px;
    font-size: 0;
    transition: none;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: color 0 ease-in;
    margin-top: 10px;
    margin-bottom: 20px;
    bottom: 0;
    left: 0;
}
}
